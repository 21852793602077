<template>
  <div>
    <!-- Header Slide Section -->
    <div
      class="header-slide mt-3"
      :style="{ backgroundImage: 'url(' + bgImages[0] + ')' }"
    >
      <div class="caption">
        <div class="box">
          <div class="container">
            <div class="inside-box py-4">
              <h1>Treatments at Amchara Malta</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Featured In Tabs -->
    <featured-in></featured-in>
    <!-- Treatments Section -->
    <div class="container my-5">
      <div class="row text-center mb-4">
        <!--  -->
        <div class="col-md-4 py-3">
          <div class="img-container">
            <img
              src="@/assets/images/treatments/mixed.jpg"
              alt="Amchara Health Retreat Treatments"
              class="treatment-img my-3"
            />
          </div>
          <h3 style="color: #83c550">Treatment Bundle</h3>
          <h3 style="color: #83c550">€700</h3>
          <p class>
            Take full advantage of all the treatments on offer at Amchara with
            this reduced rate treatment package. The Big Bundle is a 10 credit
            treatment package.
          </p>
        </div>
        <!--  -->

        <div class="col-md-4 py-3" v-for="t in malta">
          <div class="img-container">
            <img :src="t.image" :alt="t.title" class="treatment-img my-3" />
          </div>
          <h3 style="color: #83c550">{{ t.title }}</h3>
          <h5 style="color: #83c550">{{ t.sub_title }}</h5>
          <h3 style="color: #83c550">
            €{{ t.euro_price }} / {{ t.duration }} mins
          </h3>
          <p class>{{ t.description }}</p>
        </div>
      </div>
      <!--  -->

      <div class="row text-center mb-4">
        <div class="col-md-4 mx-auto">
          <router-link to="/enquiries" class="btn btn-lg btn-orange-gradient"
            >ENQUIRE NOW</router-link
          >
        </div>
      </div>

      <!--  -->
    </div>

    <!-- Testimonials -->
    <testimonials></testimonials>

    <!-- Newsletter -->
    <newsletter-cta></newsletter-cta>
  </div>
</template>

<script>
import Testimonials from "./page-partials/Testimonials";
import NewsletterCta from "./page-partials/NewsletterCta";
import FeaturedIn from "./page-partials/FeaturedIn";
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      uk: [],
      malta: [],
      bgImages: [require("@/assets/images/header/treatments_header.jpg")],
    };
  },
  methods: {
    fetchTreatments() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/treatments")
        .then(({ data }) => {
          this.uk = data.uk;
          this.malta = data.malta;
        });
    },
  },
  mounted() {
    this.fetchTreatments();
  },
  components: {
    Testimonials,
    NewsletterCta,
    FeaturedIn,
  },
};
</script>

<style scoped>
  .img-container {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    height: 325px;
  }

  .treatment-img {
    height: 300px;
    max-width: 80%;
  }
</style>